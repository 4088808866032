import lablePop from '../../components/lablePop.vue' //弹窗
export default {
    name: 'adminInfo',
    components: {
        lablePop
    },
    data() {
        return {
            codeData: '',
            // orderInfo: {
            //     "type": 'dy',
            //     "act_end_time": 1714492799,
            //     "can_use": true,
            //     "check_num": 1,
            //     "coupon": {
            //         "expire_time_end": 0,
            //         "expire_time_start": 0,
            //         "id": 0,
            //         "money": 0,
            //         "ranges": [],
            //         "sn": "",
            //         "sub_title": "",
            //         "title": "",
            //         "type": 0,
            //         "usage_limit": 0,
            //         "usage_limit_upper": 0
            //     },
            //     "group_status": 0,
            //     "id": 34092,
            //     "mall_id": 1,
            //     "order_sn": "202404020541",
            //     "order_time": 1712040526,
            //     "order_type": 1,
            //     "pay_time": 1712040578,
            //     "price": 0.01,
            //     "refund_price": 0,
            //     "refund_time": 0,
            //     "special_date": [],
            //     "status": 2,
            //     "ticket_amount": 0.01,
            //     "ticket_num": 28,
            //     "ticket_surplus_num": 0,
            //     "ticket_time": 1711987200,
            //     "ticket_title": "亲子一大一小·畅玩通票",
            //     "ticket_type": 3,
            //     "weekday": 1,
            //     "weekend": 1
            // },
            orderInfo: null,
            user_data: {},
            pop_show: false,
            isDay: 1,
            is_click: true,
            show_data: null,
            mall_list: null,
            mall_list_type: null,
            loadingVisible: false
        }
    },
    created() {
        let _this = this;
        document.title = "管理员中心";
        this.isDate(); //区分工作日
        this.$UtilsApi.memberInfo({}).then(res => {
            if (res.data.code == 0) {
                this.user_data = res.data.data;
                localStorage.setItem('user_id', this.user_data.id)
            }
        });
    },
    methods: {
        hidePop(e) {
            this.pop_show = false;
        },
        isDate() {
            let today = new Date();
            let dayOfWeek = today.getDay(); // 获取当前星期几，返回0-6，分别表示周日到周六
            this.isDay = dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday'; //1为双休日
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        // 核销记录
        toWrite() {
            this.$router.push({
                name: 'write'
            });
        },
        // 门票退款
        toRefund() {
            this.$router.push({
                name: 'refund'
            });
        },
        //核销票据
        manageCheck() {
            if (!this.is_click) {
                return
            }
            this.$UtilsApi.manageCheck(this.codeData).then(res => {
                if (res.data.code == 0) {
                    this.show_data = res.data.data.check_num;
                    this.setWrite('mr', this.orderInfo.ticket_title, res.data.data.mobile, res.data.data.chk_time, this.orderInfo.ticket_type);
                    setTimeout(() => {
                        this.show_data = null;
                    }, 2000);
                    this.orderInfo = null;
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    this.orderInfo = null;
                }
            })
        },
        mtCheck() {
            this.loadingVisible = true;
            this.$UtilsApi.checkThirdCoupon({
                chk_num: this.orderInfo.available_count,
                receipt_code: this.orderInfo.receipt_code,
                active_id: this.orderInfo.mall_id,
                channel: this.orderInfo.type
            }).then(res => {
                if (res.data.code == 0) {
                    this.$notify({ type: 'success', message: '核销成功', duration: 2000 });
                    this.setWrite(this.orderInfo.type, this.orderInfo.deal_title, res.data.data.mobile, res.data.data.chk_time, 1, this.orderInfo.available_count);
                    this.orderInfo = null;
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    this.orderInfo = null;
                }
                this.loadingVisible = false;
            })
        },
        //添加核销记录
        setWrite(type, name, mobile, chk_time, ticket_type, kx_num) {
            let data = {
                type: type,
                name: name,
                mobile: mobile,
                chk_time: chk_time,
                ticket_type: ticket_type,
                info: this.orderInfo
            }
            kx_num ? data.kx_num = kx_num : '';
            if (!localStorage.getItem('write_' + type + localStorage.getItem('user_id'))) {
                localStorage.setItem('write_' + type + localStorage.getItem('user_id'), JSON.stringify([data]));
            } else {
                let arr = JSON.parse(localStorage.getItem('write_' + type + localStorage.getItem('user_id')));
                if (arr.length < 20) {
                    arr.unshift(data);
                } else {
                    arr.pop();
                    arr.unshift(data);
                }
                localStorage.setItem('write_' + type + localStorage.getItem('user_id'), JSON.stringify(arr));
            };
        },
        close() {
            this.codeData = '';
            this.orderInfo = null;
        },
        //获取订单详情
        getOrderInfo(id) {
            this.$UtilsApi.manageInfo(id, {}).then(res => {
                if (res.data.code == 0) {
                    this.orderInfo = res.data.data;
                    this.orderInfo.type = 'mr';
                    // this.is_click = this.orderInfo[this.isDay] == 1 && this.orderInfo.special_date.indexOf(this.getNewDate(new Date()).split('.').join('-')) == -1;
                    console.log(res.data.data);
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        cancel(type) {
            this.mall_list_type = type;
            this.$UtilsApi.thirdChannel({
                channel: type
            }).then(res => {
                if (res.data.data.length == 0) {
                    this.mall_list = 'no';
                } else if (res.data.data.length == 1) {
                    this.scaneMethod(type, res.data.data[0].id);
                } else {
                    this.mall_list = res.data.data;
                }
            })
        },
        //调起微信扫一扫
        scaneMethod(type, id) {
            var _this = this;
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') !== -1;
            _this.mall_list = null;
            if (!isWeixin) {
                alert('请用微信打开连接，才可使用扫一扫');
                return
            }

            this.$UtilsApi.jsConfig({
                t_url: decodeURIComponent(location.href.split("#")[0]),
            }).then(res => {

                if (res.data.code == 0) {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                        appId: _this.$appId, // 必填，公众号的唯一标识
                        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名
                        jsApiList: ['scanQRCode'] // 必填，需要使用的 JS 接口列表
                    });
                    wx.ready(function() {
                        window.wx.scanQRCode({
                            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                            scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                            success: function(res) {
                                if (type == 'mt' || type == 'dy') {
                                    _this.$UtilsApi.viewThirdCoupon({
                                        sn: res.resultStr,
                                        active_id: id,
                                        channel: type
                                    }).then(res => {
                                        if (res.data.code == 0) {
                                            _this.orderInfo = res.data.data;
                                            _this.orderInfo.mall_id = id;
                                            _this.orderInfo.type = type;
                                        } else {
                                            _this.$notify({ type: 'warning', message: res.data.msg, duration: 5000 });
                                        }
                                    });
                                    return
                                }
                                if (JSON.parse(res.resultStr).sign) {
                                    _this.codeData = JSON.parse(res.resultStr);
                                    _this.getOrderInfo(JSON.parse(res.resultStr).order_id)
                                } else {
                                    _this.$notify({ type: 'warning', message: '二维码错误，请重新扫描', duration: 2000 });
                                }
                            }
                        });
                        // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
                    });
                    wx.error(function(res) {
                        alert('配置失败', res);
                        // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                    });
                }
            });
        }

    },
}